<template>
  <div>
    <div class="google-map" ref="googleMap"/>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { includes, debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '@/services/eventBus'
import { getFullBoundsFromNESW } from '@/services/location'

export default {
  name: 'GoogleMapLoader',

  props: {
    mapConfig: Object,
    apiKey: String,
    google: Object,
  },

  data: () => ({
    map: null,
    boundsSet: false,
    boundsListenerSet: false,
    zoomOutButtonListenerSet: false,
    localBounds: null,
    currentBounds: null,
    topLeft: null,
    bottomRight: null,
  }),

  async mounted() {
    // adding eventBus listener
    eventBus.$on('initialize-map', () => {
      this.initializeMap()
    })
    eventBus.$on('reinitialize-map', () => {
      this.reinitializeMap()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off('initialize-map')
    eventBus.$off('reinitialize-map')
    this.removeIdelListener()
  },

  computed: {
    ...mapGetters('map', [
      'mapBounds',
      'geoBoundingBox',
      'searchBounds',
      'routeSetup',
    ]),
    ...mapGetters('location', [
      'useCurrentLocation',
    ]),
    ...mapGetters('garden', [
      'loadingGardenList',
    ]),

    currentZoomLevel() {
      return this.map ? this.map.getZoom() : null
    },
  },

  watch: {
    async localBounds(newBounds, oldBounds) {
      if (newBounds !== oldBounds) {
        if (!this.boundsSet) {
          this.addMapBounds(newBounds)
          this.boundsSet = true
        } else if (this.$route.name === 'List') {
          if (this.searchBounds) {
            await this.clearSearch()
          }

          this.updateMapSearch(true)

          this.addAndFilterBounds(newBounds)
        }
      }
    },

    useCurrentLocation(value) {
      const platform = Capacitor.getPlatform();

      if (!value && (platform === 'android' || platform === 'ios')) {
        this.removeIdelListener();
      }
    },

    async geoBoundingBox(newGeoBoundingBox, oldGeoBoundingBox) {
      if (newGeoBoundingBox && newGeoBoundingBox !== oldGeoBoundingBox && this.routeSetup && this.map) {
        if (this.searchBounds) {
          await this.setSearchBounds()
        } else {
          await this.setGeoBounds(newGeoBoundingBox)
        }
      }
    },

    async map(value) {
      if (value && (this.geoBoundingBox || this.searchBounds)) {
        if (this.searchBounds && this.$route.name !== 'Garden') {
          await this.setSearchBounds()
        } else {
          if (this.geoBoundingBox && this.$route.name !== 'Garden') {
            await this.setGeoBounds(this.geoBoundingBox)
          }
        }
      }
    },
  },

  methods: {
    ...mapActions('map', [
      'addMapBounds',
      'addAndFilterBounds',
    ]),

    ...mapActions('garden', [
      'clearSearch',
      'updateMapSearch',
    ]),

    initializeMap() {
      return new Promise((resolve) => {
        const mapContainer = this.$refs.googleMap

        if (this.google) {
          this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
          this.initZoomOutButton();
        }

        if (!this.geoBoundingBox || this.$route.name === 'Garden') {
          this.setBoundsFromPoint();
        }

        resolve();
      });
    },

    async initZoomOutButton() {
      const zoomOutButton = document.querySelector('.zoom-out-button');

      if (zoomOutButton) {
        if (this.zoomOutButtonListenerSet) {
          await this.removeZoomOutButtonlListener(zoomOutButton)
        }
        if (!this.zoomOutButtonListenerSet) {
          zoomOutButton.addEventListener('click', async () => {
            const currentZoom = this.map.getZoom();

            let zoomedOut = currentZoom - 1;

            if (window.outerWidth < 840) {
              zoomedOut = currentZoom - 2;
            }

            // Clamp
            if (currentZoom === this.mapConfig) {
              zoomedOut = currentZoom;
            }

            await this.map.setZoom(zoomedOut);

            if (this.searchBounds) {
              await this.clearSearch()
            }

            this.currentBounds = this.map.getBounds()

            this.localBounds = getFullBoundsFromNESW({
              northeast: this.currentBounds.getNorthEast(),
              southwest: this.currentBounds.getSouthWest(),
            })
          });
          this.zoomOutButtonListenerSet = true
        }
      }
    },
    removeZoomOutButtonlListener(zoomOutButton) {
      return new Promise((resolve) => {
        zoomOutButton.removeEventListener('click', () => {
          this.zoomOutButtonListenerSet = false
        })
        resolve()
      })
    },

    async reinitializeMap() {
      this.boundsSet = null
      await this.removeIdelListener()

      this.map = null
      await this.initializeMap()

      await this.setSearchBounds()
    },

    setBoundsFromPoint() {
      const bounds = new this.google.maps.LatLngBounds();
      const latLng = new google.maps.LatLng(this.mapConfig.center);

      bounds.extend(latLng);
      this.map.fitBounds(bounds)
      if (this.$route.name === 'Garden') {
        this.map.setZoom(16)

        this.currentBounds = bounds

        this.localBounds = getFullBoundsFromNESW({
          northeast: this.currentBounds.getNorthEast(),
          southwest: this.currentBounds.getSouthWest(),
        })
      } else {
        this.addIdleListener();
      }
    },

    setCurrentAndLocalBounds() {
      if (!this.loadingGardenList) {
        this.localBounds = getFullBoundsFromNESW({
          northeast: this.currentBounds.getNorthEast(),
          southwest: this.currentBounds.getSouthWest(),
        })
      }
    },

    addIdleListener() {
      if (!this.boundsListenerSet && this.map.getBounds() && !includes(['Garden'], this.$route.name)) {
        this.google.maps.event.addListener(this.map, 'idle', debounce(() => {
          this.currentBounds = this.map.getBounds()

          this.setCurrentAndLocalBounds()
        }, 100))

        this.boundsListenerSet = true
      }
    },
    removeIdelListener() {
      return new Promise((resolve) => {
        if (this.boundsListenerSet && this.map && this.google) {
          this.google.maps.event.addListener(this.map, 'idle')

          this.boundsListenerSet = false
        }
        resolve()
      })
    },

    setGeoBounds(geoBoundingBox) {
      return new Promise((resolve) => {
        const topLeft = new this.google.maps.LatLng(geoBoundingBox.top_left.lat, geoBoundingBox.top_left.lng)
        const bottomRight = new this.google.maps.LatLng(geoBoundingBox.bottom_right.lat, geoBoundingBox.bottom_right.lng)
        const boundingBox = new this.google.maps.LatLngBounds(topLeft, bottomRight)

        this.map.fitBounds(boundingBox)
        this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
          this.currentBounds = this.map.getBounds()
          if (this.$route.name === 'Garden') {
            this.map.setZoom(16)
          } else {
            this.addIdleListener()
          }
        }.bind(this));
        resolve();
      });
    },

    setSearchBounds() {
      return new Promise((resolve) => {
        const northeast = new this.google.maps.LatLng(this.searchBounds.top_right.lat, this.searchBounds.top_right.lng)
        const southWest = new this.google.maps.LatLng(this.searchBounds.bottom_left.lat, this.searchBounds.bottom_left.lng)

        const boundingBox = new this.google.maps.LatLngBounds(southWest, northeast)

        this.map.fitBounds(boundingBox)

        this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
          this.setMapZoomLevel(this.searchBounds.bottom_left.lng, this.searchBounds.top_right.lng)
          this.addIdleListener()
        }.bind(this));

        resolve();
      });
    },
    setMapZoomLevel(southWestLng, northEastLng) {
      const GLOBE_WIDTH = 256 // a constant in Google's map projection

      const west = southWestLng
      const east = northEastLng
      let angle = east - west
      if (angle < 0) {
        angle += 360
      }

      const pixelWidth = document.getElementById('map-section').clientWidth

      const zoom = Math.round(Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2)

      this.map.setZoom(zoom)
    },
  },
}

</script>

<style scoped>

.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
