<template>
  <img class="placeholder-image" src="@/assets/images/garden-placeholder.png" alt="Place holder">
</template>

<script>
export default {
  name: 'img-placeholder',
}
</script>

<style scoped>
  img.placeholder-image {
    border-radius: 3px;
    -o-object-fit: cover;
    object-fit: cover;
}
</style>
