const markerOpening = require('@/assets/images/map-images/new-markers/marker-opening.png')
const markerOpeningGroup = require('@/assets/images/map-images/marker-opening-group.svg')
const markerArrangement = require('@/assets/images/map-images/new-markers/marker-arrangement.png')
const markerArrangementGroup = require('@/assets/images/map-images/marker-arrangement-group.svg')
const markerOpeningAndArrangement = require('@/assets/images/map-images/new-markers/marker-opening-and-arrangement.png')
const markerCluster = require('@/assets/images/map-images/new-markers/marker-cluster.png')
const markerClusterPng = require('@/assets/images/map-images/new-markers/marker-cluster.png')
const markerClosed = require('@/assets/images/map-images/new-markers/marker-closed.png')
const markerLocation = require('@/assets/images/map-images/new-markers/map-marker-alt-solid.svg')

const markerIcons = {
  markerOpening,
  markerOpeningGroup,
  markerArrangement,
  markerArrangementGroup,
  markerOpeningAndArrangement,
  markerCluster,
  markerClusterPng,
  markerClosed,
  markerLocation,
}

const treeBlack = require('@/assets/images/map-images/tree-black.svg')
const treeGroupBlack = require('@/assets/images/map-images/tree-group-black.svg')
const treeWhite = require('@/assets/images/map-images/tree-white.svg')
const treeGroupWhite = require('@/assets/images/map-images/tree-group-white.svg')

const otherIcons = {
  treeBlack,
  treeGroupBlack,
  treeWhite,
  treeGroupWhite,
}

const mapSettings = {
  clickableIcons: false,
  streetViewControl: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  zoomControlOptions: {
    style: 'SMALL',
    scaleControl: true,
  },
  controlSize: 20,
  zoom: 16,
  minZoom: 6,
  maxZoom: 16,
}

export {
  mapSettings,
  markerIcons,
  otherIcons,
}
