<template>
  <div class="icon-group-wrapper">
    <ngs-icon v-for="(tag, index) in tagsToUse"
      :key="index"
      :tag="tag"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NgsIcon from '@/components/ngsIcons/NgsIcon';

export default {
  name: 'NgsIconGroup',
  props: {
    tags: Array,
    loadedTags: Array,
  },

  components: {
    NgsIcon,
  },

  computed: {
    ...mapGetters('ngsicons', [
      'localTags',
    ]),

    tagsToUse() {
      return this.loadedTags ?? this.localTags(this.tags)
    },
  },
}
</script>

<style scoped lang="scss">
</style>
