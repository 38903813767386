<template>
  <span
    :alt="alt"
    :title="tag.name"
    class="ngs-icon"
    :class="tag.icon"
    v-b-tooltip.hover.bottom="{ customClass: 'ngs-dark-tooltip', disabled: !showTooltip }">
  </span>
</template>

<script>
export default {
  name: 'NgsIcon',
  props: {
    showTooltip: {
      type: Boolean,
      default: true,
    },
    alt: String,
    title: String,
    tag: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.ngs-icon {
  height: 19px;

  &.bnb::before {
    content: 'a';
  }

  &.teas::before {
    content: 'b';
  }

  &.cashless::before {
    content: 'c';
  }

  &.nccpg::before {
    content: 'd';
  }

  &.wheelchair::before {
    content: 'e';
  }

  &.dogs::before {
    content: 'f';
  }

  &.plants::before {
    content: 'y';
  }

  &.picnics::before {
    content: 'p';
  }

  &.coaches::before {
    content: "C";
  }

  &.flowers::before {
    content: "z";
  }

  &::before {
    font-family: "NGS Icons";
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
</style>
